@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 100;
    src: url('montserrat/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 200;
    src: url('montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('montserrat/Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('montserrat/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('montserrat/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('montserrat/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    src: url('montserrat/Montserrat-Black.ttf') format('truetype');
}

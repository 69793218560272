.test-creation-container {
    height: 15%;
    display: flex;
    flex-wrap: wrap;
}

.unit-list {
    flex: 2;
    margin-right: 10px;
    margin-top: 10px;
    scroll-behavior: auto;
}

.event-list {
    flex: 3;
    margin-left: 10px;
    margin-top: 10px;
}

.test-event-list {
    margin-top: 10px;
    width: 100%;
}

.style {
    margin: 20px
}

.test-meta-info-container {
    margin: 10px 0 10px;
}

.test-meta-info {
    width: 100%
}

button.add-event {
    margin: 8px 8px;
    right: 8px;
}

button.create-test {
    margin-top: -20px;
}

button.unit {
    width: 100%;
}

.unit-list-items {
    max-height: 360px;
    overflow: auto;
}

.test-date {
    margin: 8px 0;
}
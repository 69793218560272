.style {
    margin: 20px;
    }
.load-spinner {
    position: fixed;
    left: 47.5%;
    top: 45%;
    }
.right {
    text-align: right;
    }
    .result_red {
        color: #ff0000
    }
    .result_orange {
        color: #ff9900
    }
    .result_green {
        color: #00ff00
    }
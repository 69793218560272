.eceh-faq__container {
  .eceh-faq__collapse-item.ant-collapse-item {
    .ant-collapse-header {
      padding-left: 24px;
      color: rgba(0, 0, 0, 0.85);

      font-size: 16px;
      font-weight: 600;

      @media (max-width: 599.99px) {
        font-size: 14px;
      }

      .ant-collapse-arrow {
        left: 0;
      }
    }

    .ant-collapse-content-active {
      .ant-collapse-content-box {
        padding-left: 24px;
        padding-top: 0;

        font-size: 14px;
        line-height: 25px;
        color: #73808D;

        a {
          color: #73808D;
          text-decoration: underline;
        }
      }
    }
  }
}
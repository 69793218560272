body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding: 1vw;
}

.ant-layout-content {
  margin-top: 64px;
  min-height: calc(100vh - 64px) !important;
  transition: margin 0.25s;
}

.ant-table-row.ant-table-row-level-0 {
  cursor: pointer;
}

.ck.ck-math-form {
  margin-left: 20px !important;
  margin-top: 20px !important;
}


.ck.ck-math-form {
  margin-left: 20px !important;
  margin-top: 20px !important;
}

.page-margin {
  margin: 20px;
}

.green-background {
  background-color: rgba(46, 204, 113, 0.7);
}

.row {
  flex-direction: row;
}

.centerItems {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.rounded {
  border-radius: 10px;
}

.ant-card.rounded {
  border-radius: 10px;
}

.center-verticaly {
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 100%;
}

@media only screen and (max-width: 1255px) {
  .display-large-screen {
    display: none !important;
  }
}

@media only screen and (min-width: 1256px) {
  .display-small-screen {
    display: none !important;
  }
}

.ant-form-item-control {
  width: 100%;
}
figure.image img {
  max-width: 100%;
  justify-self: center;
}
figure.image {
  display: flex;
  justify-content: center;
}
figure.image figcaption {
  display: none;
}

.container{
  position:relative;
  margin-left:auto;
  margin-right:auto;
  padding-right:15px;
  padding-left:15px
}
@media (min-width: 476px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 768px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 992px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 1200px){
  .container{
    padding-right:15px;
    padding-left:15px
  }
}
@media (min-width: 476px){
  .container{
    width:100%
  }
}
@media (min-width: 768px){
  .container{
    width:720px;
    max-width:100%
  }
}
@media (min-width: 992px){
  .container{
    width:960px;
    max-width:100%
  }
}
@media (min-width: 1200px){
  .container{
    width:1140px;
    max-width:100%
  }
}
@media (min-width: 1400px){
  .container{
    width:1340px;
    max-width:100%
  }
}

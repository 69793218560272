.button-sendout-results {
  float: right;
}

span.panel-progress-bar,
span.panel-completed-tasks {
  display: inline-block;
  vertical-align: middle;
}

span.panel-progress-bar {
  width: 80%;
}

span.panel-completed-tasks {
  width: 20%;
  text-align: center;
}

.text-columns {
  text-align: center;
}

form.eceh-registration-tab__login-form {
    .eceh-registration-tab__h3 {
      margin-bottom: 6px;
      margin-top: 16px;
      padding-left: 2px;
  
      font-size: 15px;
      line-height: 18px;
  
      &:nth-of-type(1) {
        margin-top: 8px;
      }
    }
  
    .eceh-registration-tab__cta-container {
      .eceh-button{
        width: 100%;
      }
    }
  }
.ant-card-head-title {
    text-align: center;
}
.centering-container {
    text-align: center;
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-around;
}
.ant-row-flex {
    margin: 0 15px;
}

@media (min-width: 768px) {
    .ant-col {
        display: unset;
    }
}
@media (max-width: 767px) {
    .ant-col {
        display: flex;
        justify-content: center;
    }
}

.ant-pagination {
    margin-bottom: 15px;
}
.test-container {
    padding: 25px;
}

button.submit-test {
    margin: 0;
}

button.close-results-button {
    margin: 10px auto;
    display: block;
}

.resultCard > .ant-card-body {
    margin: auto;
    align-self: center;
}

.ant-card.resultCard {
    margin: 10px;
    height: 90%;
    text-align: center;
    display: flex;
}

.question > div:nth-child(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 10px;
}
.question > div:nth-child(2) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 10px;
}

.activities-table__table .public-img {
    display: block;
    height: 1.4rem;
    padding: 0;
    margin: 0 auto;
}


.ant-table-row.ant-table-row-level-0.disabled {
    cursor: not-allowed;
}

.eceh-project-overview__video {
  width: 100%;
  height: auto;
  border-radius: 12px;
}

.eceh-project-overview__information-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  margin-bottom: 32px;

  .eceh-project-overview__information-title {
    margin: 0;
    color: #73808D;

    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0;
  }

  .eceh-project-overview__information-description {
    margin: 0;
  }
}

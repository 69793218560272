.ant-card-head-title {
    text-align: center;
}
.result_red {
    color: #ff0000
}
.result_orange {
    color: #ff6600
}
.result_green {
    color: #00ff00
}
.centering-container {
    text-align: center;
    white-space: nowrap;
    flex-direction: column;
    justify-content: space-around;
}
.space-around-row {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
}
.space-around-row > * {
    display: block;
}

.test-container {
    padding: 20px 1% 1%;
}

.rounded-top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.rounded-bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.left-space {
    padding-left: 15px;
}

.help-card {
    margin: 5px;
}

.eceh-main-card {
  background: white;
  border-radius: 12px;

  padding: 64px;
  margin-bottom: 64px;

  position: relative;

  @media (max-width: 1023.99px) {
    padding: 48px;
    margin-bottom: 48px;
  }

  @media (max-width: 849.99px) {
    padding: 32px;
    margin-bottom: 32px;
  }

  @media (max-width: 599.99px) {
    padding: 32px 16px 16px 16px;
    margin-bottom: 24px;
  }

  &.eceh-main-card--hero {
    background-image: url("/images/title-page/title-hero-background.svg");
    background-repeat: no-repeat;

    background-position: top center;
  }

  &.eceh-main-card--dark {
    background-color: #18191B;
  }

  .eceh-main-card__heading {
    display: flex;
    flex-direction: column;
    gap: 16px;

    margin-bottom: 32px;

    > h2 {
      font-size: 28px;
      font-weight: 600;
      line-height: 34px;
      text-align: center;

      margin: 0;

      @media (max-width: 849.99px) {
        font-size: 26px;
        line-height: 31px;
      }

      @media (max-width: 599.99px) {
        font-size: 24px;
        line-height: 29px;
      }
    }

    > p {
      color: #73808D;
      margin: 0;
    }
  }
}

.eceh-title-section__top {
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin: 0 auto 64px;
  max-width: 800px;

  @media (max-width: 599.99px) {
    gap: 24px;
    margin-bottom: 48px;
  }

  > h1 {
    font-family: Montserrat, Inter, sans-serif;
    font-size: 48px;
    font-weight: 600;
    line-height: 58px;
    text-align: center;
    margin: 0;

    @media (max-width: 849.99px) {
      font-size: 44px;
      line-height: 53px;
    }

    @media (max-width: 599.99px) {
      font-size: 40px;
      line-height: 48px;
    }

    + p {
      font-size: 17px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      margin: 0;

      @media (max-width: 599.99px) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .eceh-title-section__languages {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;

    .eceh-title-section__language {
      display: flex;
      gap: 8px;

      font-weight: 500;
      color: #73808D;

      cursor: pointer;

      &:first-child > img {
        width: 24px;
      }

      &:nth-child(2) > img {
        width: 19px;
      }
    }
  }
}

/* Bottom section */
.eceh-title-section__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;

  @media (max-width: 599.99px) {
    gap: 12px;
  }

  .ant-btn.ant-btn-link > span {
    font-size: 14px;
    line-height: 17px;

    color: #444758;
  }
}

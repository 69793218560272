.eceh-contact-page {
  .eceh-contact-page__address-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    gap: 16px;
    align-items: center;

    .eceh-contact-page__ac-inner {
      display: flex;
      flex-direction: column;
      gap: 32px;

      .eceh-contact-page__address {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .eceh-contact-page__address-title {
          font-size: 17px;
          font-weight: 600;
        }
      }
    }

    > img {
      @media (max-width: 1023.99px) {
        width: 380px;
      }

      @media (max-width: 849.99px) {
        width: 280px;
      }

      @media (max-width: 599.99px) {
        display: none;
      }
    }
  }

  .eceh-contact-page__map-container {
    width: 100%;
    height: 440px;
    border-radius: 12px;
    overflow: hidden;
    border: 1px solid #ddd;

    @media (max-width: 849.99px) {
      height: 380px;
    }


    @media (max-width: 599.99px) {
      height: 280px;
    }

    .eceh-contact-page__location-container {
      position: absolute;
      top: -100px;
      left: -77px;
    }
  }
}
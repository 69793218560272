/* Norway grants description */
.eceh-grants__description-container {
  display: flex;
  justify-content: space-between;
  gap: 48px;

  margin-bottom: 64px;

  @media (max-width: 849.99px) {
    gap: 32px;
    margin-bottom: 32px;
  }

  @media (max-width: 599.99px) {
    margin-bottom: 24px;
  }

  .eceh-grants__description-hero {
    width: 280px;
    height: auto;
    align-self: flex-start;
    border-radius: 12px;

    transition: all ease-in 100ms;

    @media (max-width: 1023.99px) {
      width: 240px;
    }


    @media (max-width: 849.99px) {
      width: 180px;
    }


    @media (max-width: 599.99px) {
      display: none;
    }
  }

  .eceh-grants__description-left {
    display: flex;
    flex-direction: column;
    gap: 48px;

    max-width: 580px;

    @media (max-width: 599.99px) {
      max-width: initial;
    }

    > .eceh-grants__description-paragraphs-container > p {
      color: #101214;
      margin: 0;

      &:first-child {
        margin-bottom: 1em;

        @media (max-width: 599.99px) {
          margin-bottom: .7em;
        }
      }
    }

    > .eceh-grants__priorities {
      @media (min-width: 850px) {
        display: flex;
      }

      @media (max-width: 849.99px) {
        display: none;
      }
    }
  }
}

/* Priorities at top level */
.eceh-grants__priorities {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 48px;

  @media (min-width: 850px) {
    display: none;
  }

  @media (max-width: 599px) {
    margin-bottom: 32px;

    > h3 {
      margin: 0;
    }
  }

  > h3 {
    font-size: 17px;
    font-weight: 600;
    line-height: 21px;

    @media (max-width: 599.99px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

/* Norway grants website information */
.eceh-grants__website-source {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  margin: 0 auto;
  max-width: 580px;

  .eceh-grants__website-source-description {
    font-size: 15px;
    line-height: 18px;
    color: #73808D;
    text-align: center;

    margin: 0;

    > a {
      color: #73808D;
      text-decoration: underline;
    }
  }
}

.eceh-policy__wrapper {
    max-width: 1052px;
    padding: 20px;
    margin: 0 auto;
}

.eceh-policy__wrapper h2.ant-typography {
    margin: 16px 0 32px 0;
}

.eceh-policy__wrapper .eceh-policy__inner h3 {
    margin: 16px 0 8px 0;
}

.eceh-policy__wrapper .eceh-policy__inner p {
    margin-bottom: 8px;
}
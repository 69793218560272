.eceh-button.ant-btn-round.ant-btn-lg {
  padding: 12px 24px;
  height: initial;
  line-height: 1.5715;

  box-shadow: none;
  text-shadow: none;
  border: 0;
  background: #40A9FF;

  > span {
    margin: 0;

    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.04em;

    text-shadow: none;
    color: white;
  }

  &.eceh-button--xs {
    padding: 8px 16px;

    > span {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
    }
  }
}
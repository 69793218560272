.EventCreation__button {
    margin:20px 20px 20px 0 !important;
}

.link-button {
    margin: 0;
    border: none;
    background-color: transparent;
    color: blue;
}

.error-border {
    border-color: red;
}

.group_item {
    padding: 0 !important; 
    margin: 0 !important;
}
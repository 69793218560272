div.eceh-sign-in-modal {
  font-family: Inter, sans-serif;
  padding: 0;

  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;

    width: 480px;
    max-width: 100%;
    margin: 0 auto;

    .ant-modal-body {
      padding: 0;
    }
  }

  .eceh-sign-in-modal__card {
    background: #f0f2f5;

    .ant-card-body {
      padding: 24px;

      .ant-tabs-nav-wrap {
        align-items: stretch;

        .ant-tabs-nav-list {
          width: 100%;
          gap: 32px;

          .ant-tabs-tab {
            width: 50%;
            justify-content: center;
            margin: 0;
          }
        }
      }

      .ant-tabs.ant-tabs-top {
        overflow: visible;
      }

      /* Inputs */
      .ant-input-affix-wrapper {
        border-radius: 12px;
        border: 1px transparent;
        padding: 0;
      }

      .ant-form-item-explain {
        transition: margin-bottom ease-in-out 100ms;
      }

      .ant-form-item {
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      input.ant-input {
        border-radius: 12px;
        height: 44px;
        padding: 4px 11px;

        font-size: 14px;
        line-height: 17px;
        font-weight: 400;
        text-align: left;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset;
        }
      }

      .ant-select-selector {
        border-radius: 12px;
        height: 44px;
        padding: 0;
        border: 1px transparent;

        cursor: pointer;

        .ant-select-selection-search {
          left: 0;

          .ant-select-selection-search-input {
            padding: 4px 11px;
            height: 44px;
          }
        }

        .ant-select-selection-item {
          padding: 4px 36px 4px 11px;
          display: flex;
          align-items: center;

          > p {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .ant-select-selection-placeholder {
          display: flex;
          place-items: center;
          padding: 4px 11px;
        }
      }


      .ant-form-item input[type='radio'], .ant-form-item input[type='checkbox'] {
        width: 18px;
        height: 18px;
      }

      .ant-checkbox-inner {
        width: 18px;
        height: 18px;
      }

      .ant-input-prefix {
        margin: 0;
        padding-left: 11px;
        font-size: 18px;
      }

      .ant-input-suffix {
        font-size: 18px;
        padding-right: 11px;
        margin: 0;
      }

      .ant-input-password {
        border-radius: 12px;
        padding: 0;
      }
    }
  }
}


div.ant-select-dropdown {
  border-radius: 8px;
  font-family: Inter, sans-serif;


  .ant-select-item-option {
    padding: 10px 12px;

    .ant-select-item-option-content {
      white-space: initial;
      overflow: initial;

      > p {
        font-size: 14px;
        line-height: 23px;
        text-align: left;
        letter-spacing: .2px;
      }
    }
  }
}


.eceh-blog-section {
  background-image: url("/images/title-page/background-cubes.svg");
  background-repeat: no-repeat;
  background-position: top 8px right;

  @media (max-width: 1023.99px) {
    background-size: 180px;
  }

  @media (max-width: 849.99px) {
    background-size: 120px;
  }

  @media (max-width: 599.99px) {
    background-image: none;
  }
}

/* Title container */
.eceh-blog-section__title-container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 580px;
  margin: 0 auto 64px;

  color: white;

  @media (max-width: 849.99px) {
    margin-bottom: 48px;
  }

  @media (max-width: 599.99px) {
    margin-bottom: 32px;
  }

  > h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;

    color: white;
    margin: 0;

    @media (max-width: 849.99px) {
      font-size: 26px;
      line-height: 31px;
    }

    @media (max-width: 599.99px) {
      font-size: 24px;
      line-height: 29px;
    }
  }
}

/* Button container */
.eceh-blog-section__cta-container {
  text-align: center;
}

.eceh-layout {
  background: #f0f2f5;
  padding: 0 16px;

  overflow-x: auto;

  /* Typography */
  font-family: Inter, sans-serif;
  text-align: left;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: black;

  @media (max-width: 599.99px) {
    font-size: 15px;
    line-height: 18px;
  }

  .eceh-layout__inner {
    max-width: 1180px;
    margin: 0 auto;
  }

  div p {
    text-align: left;
  }

  a {
    color: black;
  }
}

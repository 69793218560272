.eceh-login-tab__recovery-submit-container {

  button.eceh-login-tab__recovery-submit {
    margin-top: 16px;

    width: 100%;
    margin-bottom: 8px;
  }
}

div.eceh-sign-in-modal .eceh-sign-in-modal__card .ant-card-body .ant-form-item.eceh-login-tab__login-submit-container {
  margin-bottom: 8px;
}

.eceh-login-tab__login-submit-container {
  button.eceh-login-tab__login-submit {
    width: 100%;
    margin-top: 16px;
  }
}
.ant-layout {
    position: relative;
}

.float-right {
    float: right
}

button.ant-switch-small::after {
    background-color: #1890ff;
}

.dark-color {
    background-color: rgb(0, 21, 41);
}

.footer {
    padding: 13.5px 50px;
    box-shadow: -2px 0 55px -3px rgba(0, 0, 0, 1);
    position: static;
    background-color: #00152A;
    color: #fff;
}

.footer a {
    color: #fff;
    transition: color 0.15s linear;
}

.footer a:hover {
    color: #1890ff;
}

footer.welcome-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    /*background-color: #00152A80;*/
}

.user-name {
    color: #a6adb4 !important;
}

/* Navigation menu item group */
.navigation-menu .ant-menu-item-group-title {
    padding-top: 0.8rem;
    font-size: 0.75rem;
    color: rgba(255, 255, 255, 1);
    padding-bottom: 0;
    font-weight: 500;
}

.languageIcon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
}

.languageText {
    color: rgba(255, 255, 255, 0.65);
}

.languageText:hover {
    color: #fff;
    cursor: pointer;
}

.ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.changeLanguageWrapper {
    padding-left: 24px;
    padding-right: 16px;
    margin-bottom: calc(64px);
    height: 40px;
}

.unit-colapse {
  margin: 10px 0 0 0;
}

.unit-colapse a {
  text-decoration: none;
  color: black;
  flex: 1;
  text-align: start;
}

.eventItem {
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 0.2s linear;
}

.eventItemDone {
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 0.2s linear;
  background-color: #d8ffbb;
}

.eventItem:hover {
  background-color: #e6f7ff;
}

.eventItemDone:hover {
  background-color: #bfff8e;
}

.ant-empty-description {
  text-align: center;
}

.exam-button-right {
  text-align: end;
}

.active-switch {
  width: 95px;
}

.ant-dropdown-menu-item-disabled a {
  color: #dddddd;
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

/* 
  responsive
  ----------
  */
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
}

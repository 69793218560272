.eceh-try-free-section {
  background-image: url("/images/title-page/background-cubes.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 280px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;

  @media (max-width: 849.99px) {
    background-size: 200px;
    gap: 48px;
  }

  @media (max-width: 599.99px) {
    background-size: 160px;
    gap: 32px;
  }

  > h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
    text-align: center;

    color: white;

    @media (max-width: 849.99px) {
      font-size: 26px;
      line-height: 31px;
    }

    @media (max-width: 599.99px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  /* Sign up */
  .eceh-try-free-section__signup-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    align-self: stretch;

    @media (max-width: 599.99px) {
      gap: 32px;
    }

    .eceh-try-free-section__signup-form-fields {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 100%;
      width: 320px;

      @media (max-width: 849.99px) {
        gap: 16px;
      }


      @media (max-width: 599.99px) {
        gap: 12px;
        width: 100%;
        max-width: 320px;
      }
    }

    /* Inputs */
    input.ant-input {
      height: 51px;
      border-radius: 12px;

      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-align: left;

      @media (max-width: 849.99px) {
        font-size: 15px;
        line-height: 18px;
      }

      @media (max-width: 599.99px) {
        font-size: 14px;
        line-height: 17px;
        height: 44px;
      }
    }

    .ant-input-password {
      border-radius: 12px;
      padding: 0;

      .ant-input {
        padding: 4px 11px;
      }

      .ant-input-suffix {
        font-size: 18px;
        padding-right: 11px;
        margin: 0;
      }
    }
  }
}

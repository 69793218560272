.eceh-footer {
  display: flex;
  margin-bottom: 64px;
  justify-content: space-between;

  align-items: flex-end;

  @media (max-width: 849.99px) {
    gap: 32px;
    margin-bottom: 48px;
  }

  @media (max-width: 599.99px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;

    margin-bottom: 32px;
  }

  .eceh-footer-left {
    display: flex;
    gap: 64px;
    flex-direction: column;

    max-width: 400px;

    @media (max-width: 849.99px) {
      gap: 48px;
      max-width: 280px;
    }

    @media (max-width: 599.99px) {
      max-width: initial;
      gap: 32px;
    }

    .eceh-footer__title-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .eceh-footer__title {
        font-family: Montserrat, Inter, sans-serif;
        font-size: 28px;
        font-weight: 600;
        line-height: 34px;
        margin: 0;

        @media (max-width: 849.99px) {
          font-size: 26px;
          line-height: 31px;
        }

        @media (max-width: 599.99px) {
          font-size: 24px;
          line-height: 29px;
        }
      }

      .eceh-footer__description {
        font-size: 17px;
        line-height: 21px;
        margin: 0;

        @media (max-width: 599.99px) {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .eceh-footer__languages-container {
        display: flex;
        vertical-align: center;
        gap: 32px;

        .eceh-footer-language {
          display: flex;
          vertical-align: center;
          gap: 8px;

          cursor: pointer;

          span {
            font-size: 17px;
            line-height: 21px;

            @media (max-width: 599.99px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .eceh-footer__socials {
      display: flex;
      gap: 32px;
      align-items: center;

      .eceh-footer__socials-grants, .eceh-footer__socials-fiit {
        > img {
          width: 100%;
          height: auto;
        }
      }

      .eceh-footer__socials-grants {
        width: 48px;
        height: auto;

        @media (max-width: 849.99px) {
          width: 44px;
        }

        @media (max-width: 599.99px) {
          width: 40px;
        }
      }

      .eceh-footer__socials-fiit {
        @media (max-width: 849.99px) {
          width: 65px;
        }

        @media (max-width: 599.99px) {
          width: 59px;
        }
      }


      > a > img {
        transition: all ease-in-out 100ms;
      }

      > a:hover > img {
        transform: scale(105%);
      }
    }
  }

  .eceh-footer-right {
    display: flex;
    gap: 64px;
    flex-direction: column;

    @media (max-width: 849.99px) {
      gap: 48px;
    }


    @media (max-width: 599.99px) {
      gap: 32px;
    }

    > section {
      display: flex;
      flex-direction: column;
      gap: 12px;

      > h3 {
        font-size: 17px;
        font-weight: 600;
        line-height: 21px;
        margin: 0;
      }
    }
  }
}

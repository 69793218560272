@media only screen and (max-width: 768px) {
    .times-list-item {
        text-align: left;
    }
}

@media only screen and (min-width: 769px) {
    .times-list-item {
        text-align: right;
    }
}

.border-bottom-collapse-header .ant-collapse-header {
    border-bottom: solid 1px #d9d9d9;
}

/* if remove this, than for this table is used css from ActivityDetail.css, IDK why */
.table table tr td {
    border: 1px solid #000000;
    padding: 8px;
}

.table table tr td:hover {
    background-color: #ddd;
}

.default-silver {
  background-color: rgb(240,242,245, 1.0);
}

.selected{
  background-color: rgba(24, 144, 255, 0.5) !important;
}

.right_a{
  background-color: rgb(33, 233, 15, 0.856) !important;
}

.wrong_a{
  background-color: rgb(236, 18, 18, 0.973) !important;
}

.wrong_b{
  background-color: rgba(236, 134, 18, 0.973) !important;
}

div p {
  text-align: justify;
  margin-bottom: 0;
}
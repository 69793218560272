.ant-spin {
    position: relative;
    left: 47.5%;
    top: 45%;
}
.loadingFilter {
    background-color: #00000050;
    position: relative;
    left:0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
}
.style {
    margin: 20px;
}

.load-spinner {
    position: fixed;
    left: 47.5%;
    top: 45%;
}

.right {
    text-align: right;
}

button.ant-btn {
    margin: 0 5px;
}

.ant-row-flex {
    margin: 0;
}

.ant-col {
    justify-content: initial;
}

.button-container-col {
    align-items: center;
    justify-content: flex-end;
    display: flex !important;
}

.ant-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header::before, .ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
    content: none
}

.ant-modal-content, .ant-modal-header, .ant-modal-body, .ant-modal-footer {
    background-color: #f0f2f5;
}

div[data-rbd-drag-handle-context-id] > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    cursor: move;
}

.ant-form-item-label {
    text-align: left;
}

/* if remove this, than for this table is used css from EventsDetail.css , IDK why*/
.table table tr td {
    border: 1px solid #000000;
    padding: 8px;
}

.table table tr td:hover {
    background-color: #ddd;
}


.public-img {
    display: inline-block;
    height: 1.4rem;
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
}

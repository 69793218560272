.eceh-secondary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 4px 0 12px 0;

  .eceh-secondary-header__navigation-links-container, .eceh-secondary-header__logged-in-user {
    display: flex;
    align-items: center;
    gap: 32px;

    @media (max-width: 599.99px) {
      gap: 24px;
    }

    @media (max-width: 349.99px) {
      gap: 16px;
    }
  }

  .eceh-secondary-header__logged-in-user {
    .eceh-secondary-header__lu-item {
      display: flex;
      gap: 8px;
      align-items: center;

      &:first-child {
        font-size: 15px;
        line-height: 18px;
        color: #404040;

        @media (max-width: 599.99px) {
          display: none;
        }
      }

      &:last-child {
        color: #40A9FF;
      }
    }
  }

  .eceh-secondary-header__home-container {
    .eceh-secondary-header__home-item {
      display: flex;
      gap: 8px;
      align-items: center;

      color: #40A9FF;
    }
  }
}
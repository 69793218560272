.goBackButton {
  border: none;
  cursor: pointer;
  background: inherit;
}

.goBackButton:hover {
  color: #40a9ff;
  background-color: inherit;
}
